/*******************************
         Site Overrides
*******************************/

@import (css) url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import (css) url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');

body ::-webkit-scrollbar {
  width: 6px;

  &:horizontal {
    height: 6px;
  }
}

body ::-webkit-scrollbar-track {
  background: none;
}

// custom material icons
.material-icons-outlined {
  font-family: 'Material Icons Outlined' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;  /* Preferred icon size: 24px */
  display: inline-block;
  line-height: 1em;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
