@import (multiple) '../ui-components/src/style/global.custom.less';

/*******************************
             Layout
*******************************/

@headerHeight: 56px;
@minWidth: 1280px;

iframe[name='__tcfapiLocator'] {
  position: absolute;
  top: -9990px;
}

iframe#adroll_slg {
  position: absolute;
  top: -9980px;
}

// simple top header only with logo
.Simple-top-header {
  background-color: @B1;
  padding: 1.5em 3.5em;
  box-shadow: none;
  border: none;

  img {
    display: block;
  }
}

// fixed footer
.App-fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--layout-padding-7);
  background: #fff;
  box-shadow: 0 -2px 6px 0 #e2e6ed;

  & > .container {
    padding: 0 var(--layout-padding-1);
  }
}

// global billing trial notification
.notification.ui.message {
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  background: @lightBackground;
  color: #666;
  font-size: 0.875em;

  .close.icon {
    right: 2.5em;
  }

  .container {
    padding: 0 3.5em;
  }
}

/*******************************
             Page
*******************************/

/* Maximum Page Width */
@containerMaxWidth: 98rem;

.container {
  width: 100%;
  max-width: @containerMaxWidth;
  margin: 0 auto;
}

// page with a Hero Header, like Sign Up/Invite Callback/Plans page
.page-with-hero-header {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: @black;
  overflow-x: hidden;

  .hero-header {
    color: #e3e8ee;

    h1 {
      margin: 0.6em 0;
      color: @primaryColor;
    }
  }

  .content-wrapper {
    flex: 1;
    width: 200%;
    transform: translateX(-25%);
    background-color: @B3;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
  }

  @media screen and (max-width: 768px) {
    .content-wrapper {
      width: 100%;
      transform: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

/*******************************
             Elements
*******************************/

.aside {
  background: @L1;
}

.borderline {
  border: 1px solid @L2;
  border-radius: @defaultBorderRadius;
}

.border-radius {
  border-radius: @defaultBorderRadius;
}

.highlight {
  padding: var(--layout-padding-6);
  background: @lightBackground;
  line-height: 1.3;
  border-radius: @defaultBorderRadius;
}

.hint {
  font-size: var(--fs8);
  color: @lightTextColor;
}

.positive {
  color: var(--positive-color);
}

.negative {
  color: var(--negative-color);
}

.count-box {
  display: inline-block;
  width: 2.1em;
  height: 2.1em;
  line-height: 2.1em;
  border-radius: 8px;
  margin: 0 0.5em;
  background: @labelColor;
  text-align: center;
  font-weight: 600;
}

.dot {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: @grey;
}

.tui-cloud-cc {
  display: inline-block;
  width: 12rem;
  height: 6rem;
  border: 1px solid @L2;
  border-radius: @relative10px;
  margin: 0.5em;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 100ms ease-in;

  &.active {
    border-color: @primaryColor;
    border-width: 2px;
  }

  &.tui-cloud-aws {
    background-size: auto;
    background-image: url(../media/logo/aws-regular.svg);
  }

  &.tui-cloud-gcp {
    background-size: calc(100% - 2em) auto;
    background-image: url(../media/logo/gcp-regular.svg);
  }

  &.tui-cloud-kafka {
    background-size: contain;
    background-image: url(../media/kafka.svg);
  }

  &.tui-cloud-mysql {
    background-size: contain;
    background-image: url(../media/mysql.svg);
  }

  &.tui-cloud-tidb {
    background-size: contain;
    background-image: url(../media/tidb-cloud-icon.svg);
  }
}

.providers {
  .tui-cloud-cc:first-child {
    margin-left: 0;
  }

  .tui-cloud-cc:last-child {
    margin-right: 0;
  }
}

// Rechart
.rechart-wrap {
  margin-top: 1rem;

  .ui.header {
    font-size: var(--fs7);
    font-weight: @normal;
  }

  .chart-loader-container,
  .rechart-empty-text {
    margin-top: var(--layout-padding-1);
    color: @lightTextColor;
  }

  .recharts-xAxis {
    .recharts-label {
      fill: @grey;
      font-size: 85%;
    }
  }
}

// Form
.form-actions {
  margin-top: 2em;
  text-align: right;

  .button {
    min-width: 6.75em;
  }

  .button:first-child:not(:last-child) {
    margin-right: 1em;
  }
}

// Table
.tui-table__body-row {
  .notice {
    color: var(--hint-color);
    text-align: center !important;
  }

  td {
    word-break: break-all;
  }
}

// overview hero card
.overview-hero {
  display: flex;

  .item {
    flex-basis: 33%;
    padding: 0 var(--layout-padding-7);
    border-left: 1px solid var(--border-color);
  }

  .value {
    display: block;
    margin-bottom: var(--layout-padding-7);
    font-size: var(--fs6);
    font-weight: 600;
  }

  .value-with-tip {
    display: flex;
    align-items: baseline;

    .tip {
      margin-left: 0.5rem;
      color: var(--hint-color);
    }
  }
}

.ui.input {
  > input:read-only {
    border-color: var(--border-color);
    opacity: 0.5;
    cursor: initial;
  }
}

.empty-clusters-box {
  min-height: 32em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .placeholder-img {
    width: 80px;
    height: 80px;
    margin-bottom: 1em;
  }

  .text {
    margin-bottom: 1em;
  }
}
// temp
.tab {
  & > .ui.segment:first-child {
    margin-top: 1em;
  }
}

.text-with-sup {
  sup {
    font-size: 65%;
    font-weight: 400 !important;
  }
}

.theme-message {
  color: var(--primary-color) !important;
  font-weight: 500 !important;

  &:hover {
    color: var(--primary-color-hover) !important;
  }
}

.error-message {
  color: var(--negative-color) !important;
  font-weight: 500 !important;

  &:hover {
    color: var(--hard-negative-color) !important;
  }
}

.warn-message {
  color: var(--unhealthy-color) !important;
}

.table {
  .buttons {
    white-space: nowrap;
  }
}

.ui.dimmer.modals {
  background-color: rgba(134, 134, 134, 0.9);
}

// hide default zendesk launcher
#launcher {
  display: none;
}

// util
// make scrollbar not take up space
// ref: https://stackoverflow.com/questions/21367441/make-scroll-bar-take-no-space-prevent-layout-shift
.overlay-y-scrollbar {
  overflow: auto;
  overflow: auto;
  overflow-y: overlay; // only works in webkit
}
