/*******************************
         Site Overrides
*******************************/

.ui.secondary.pointing.menu {
  border-bottom: none;

  .item {
    &.active {
      border-color: @blue;

      &:hover {
        border-color: @blue;
      }
    }
  }

  .dropdown.item {
    a.item {
      border: none;
    }
  }
}

.ui.secondary.pointing.menu:not(.pagination):not(.vertical) {
  > .item {
    margin-right: 2em;
    padding-left: 0;
    padding-right: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

/*--------------
   Pagination
---------------*/

.ui.pagination.secondary.pointing.menu {
  border-bottom: none;
}
