/*******************************
        User Overrides
*******************************/

.ui.dropdown > .dropdown.icon {
  font-family: 'Material Icons Outlined';
  color: @lightTextColor;
  font-size: 1.2em;
  vertical-align: middle;
}

.ui.dropdown > .dropdown.icon:before {
  content: "\e5cf";
}

.ui.selection.dropdown > .dropdown.icon {
  padding: 0.5em;
}

.ui.dropdown.loading > .dropdown.icon {
  width: 1.23em
}


.ui.dropdown > .menu {
  overflow-x: hidden;
}
