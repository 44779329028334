// Variables of semantic-ui-less
@type: 'global';
@element: 'site';

// Import variables from semantic-ui-less
@import (multiple) './global.variables.less';

// CSS Variables for TiDB Cloud UI
:root {
  --body-bg: @B3;
  --aside-bg: @B2;
  --card-bg: @white;
  --light-bg: @B2;
  --blue-header: @indigo;

  --title-color: @darkTextColor;
  --text-color: @textColor;
  --text-color-light: @grey;
  --disabled-color: @F1;
  --disabled-text: @F4;
  --secondary-text: @F3;
  --hint-color: @lightTextColor;
  --divider-color: @L1;
  --border-color: @L2;
  --label-color: @labelColor;
  --primary-color: @primaryColor;
  --primary-color-hover: @M2;
  --primary-color-pressed: @M3;
  --selected-color: @primaryColor;
  --default-button-bg: @defaultButtonBackground;

  --positive-color: @green;
  --light-positive-color: #84d79d;
  --negative-color: @S3-1;
  --hard-negative-color: @S3-2;
  --harder-negative-color: @S3-3;
  --light-negative-color: #f9b5b1;

  --normal-color: @green;
  --creating-color: @indigo;
  --unavailable-color: @red;
  --terminating-color: @purple;
  --leaving-color: @orange;
  --scaling-color: #41c4d5;
  --upgrading-color: #536fe3;
  --recovering-color: @S4-2;
  --unhealthy-color: @S2-1;
  --unhealthy-bg-color: @S2-p1;
  --importing-color: @S5-1;
  --modifying-color: @S5-1;
  --resuming-color: @S4-1;
  --paused-color: @S2-1;
  --unknown-color: @grey;

  --fs1: 4rem;
  --fs2: 3rem;
  --fs3: 1.875rem;
  --fs4: 1.5rem;
  --fs5: 1.25rem;
  --fs6: 1.125rem;
  --fs7: 1rem;
  --fs8: 0.875rem;

  --layout-padding-1: 3rem;
  --layout-padding-2: 2.5rem;
  --layout-padding-3: 2rem;
  --layout-padding-4: 1.75rem;
  --layout-padding-5: 1.5rem;
  --layout-padding-6: 1.25rem;
  --layout-padding-7: 1rem;
  --layout-padding-8: 0.5rem;

  --card-shadow: 0 2px 8px 0 rgba(80, 87, 107, 0.16);

  --border-radius: @defaultBorderRadius;
  --L1: @L1;
  --A1: #f2aada;
  --A2: #c2aaf2;
  --B3: @B3;
  --B5: @B5;
  --F3: @F3;
  --S1-1: @green;
  --DropdownHover: @itemHoverColor;
  --link-color: @linkColor;
  --S3-1: @S3-1;
  --S1-p1: @S1-p1;
  --S3-p1: @S3-p1;
  --Success-5: #52cc7a;
  --Success-7: #299260;
  --S2-1: @S2-1;
  --S2-p1: @S2-p1;
  --S5-1: @S5-1;
  --S5-p1: rgba(@S5-1, 0.1);
}

/**
 * font-family
 **/
button,
input,
optgroup,
select,
textarea {
  font-family: @fontName;
}

/*******************************
             Utils
*******************************/

/**
 * For link
 */

a,
.link {
  color: @linkColor;
  text-decoration: none;
  cursor: pointer;
  outline: none;

  &:hover {
    color: @hoverColor;
    text-decoration: underline;
  }
}

/**
 * For text
 */

.word-break {
  word-wrap: break-word;
  word-break: break-word; /* webkit/blink browsers */
  white-space: normal;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// flex utils for layout
.flexbox {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.col-flexbox {
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

.flex-auto {
  flex: 1 1 auto;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
