/* windicss layer base */

/* windicss layer components */

/* windicss layer utilities */
.wd-bg-\[\#CCCCCC\] {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--tw-bg-opacity));
}
.wd-bg-\$S1-1 {
  background-color: var(--S1-1);
}
.wd-bg-\$S3-1 {
  background-color: var(--S3-1);
}
.wd-bg-\$S2-1 {
  background-color: var(--S2-1);
}
.wd-bg-\$S5-1 {
  background-color: var(--S5-1);
}
.wd-bg-\[\#e8f5ff\] {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 245, 255, var(--tw-bg-opacity));
}
.wd-rounded-1 {
  border-radius: 100%;
}
.wd-rounded-lg {
  border-radius: 0.5rem;
}
.wd-border-solid {
  border-style: solid;
}
.wd-border-0 {
  border-width: 0px;
}
.wd-border {
  border-width: 1px;
}
.wd-border-b {
  border-bottom-width: 1px;
}
.wd-border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.wd-cursor-pointer {
  cursor: pointer;
}
.wd-cursor-not-allowed {
  cursor: not-allowed;
}
.wd-cursor-default {
  cursor: default;
}
.wd-inline-block {
  display: inline-block;
}
.group:hover .group-hover\:wd-inline-block {
  display: inline-block;
}
.wd-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.wd-inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.wd-grid {
  display: -ms-grid;
  display: grid;
}
.wd-hidden {
  display: none;
}
.wd-flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.wd-flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.wd-items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.wd-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.wd-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.wd-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.wd-justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.wd-flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
}
.wd-flex-none {
  -webkit-box-flex: 0;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
.wd-flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.wd-font-700 {
  font-weight: 700;
}
.wd-font-bold {
  font-weight: 700;
}
.wd-h-full {
  height: 100%;
}
.wd-h-\[192px\] {
  height: 192px;
}
.wd-h-600px {
  height: 600px;
}
.wd-h-\[400px\] {
  height: 400px;
}
.wd-h-\[2px\] {
  height: 2px;
}
.wd-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.wd-leading-8 {
  line-height: 2rem;
}
.wd-m-0 {
  margin: 0px;
}
.wd--m-1 {
  margin: -0.25rem;
}
.wd-mr-0 {
  margin-right: 0px;
}
.wd-mr-10px {
  margin-right: 10px;
}
.wd-mb-6 {
  margin-bottom: 1.5rem;
}
.wd-ml-2 {
  margin-left: 0.5rem;
}
.wd-ml-8px {
  margin-left: 8px;
}
.wd--ml-1 {
  margin-left: -0.25rem;
}
.wd--mr-1 {
  margin-right: -0.25rem;
}
.wd-mb-16px {
  margin-bottom: 16px;
}
.wd-mb-4 {
  margin-bottom: 1rem;
}
.wd-ml-4 {
  margin-left: 1rem;
}
.wd-mb-2 {
  margin-bottom: 0.5rem;
}
.wd-mt-8 {
  margin-top: 2rem;
}
.wd-ml-8 {
  margin-left: 2rem;
}
.wd-mr-12 {
  margin-right: 3rem;
}
.wd-ml-4px {
  margin-left: 4px;
}
.wd-mb-8px {
  margin-bottom: 8px;
}
.wd-max-w-screen-lg {
  max-width: 1024px;
}
.wd-min-w-\[max\(40\%\2c 407px\)\] {
  min-width: max(40%,407px);
}
.wd-min-w-\[max\(40\%\2c 252px\)\] {
  min-width: max(40%,252px);
}
.wd-overflow-auto {
  overflow: auto;
}
.wd-overflow-hidden {
  overflow: hidden;
}
.wd-p-4 {
  padding: 1rem;
}
.wd-px-\[24px\] {
  padding-left: 24px;
  padding-right: 24px;
}
.wd-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.wd-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.wd-px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.wd-px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.wd-py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.children\:wd-px-2 > * {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.children\:wd-py-2 > * {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.wd-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.wd-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.wd-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.wd-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.wd-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.wd-pb-8 {
  padding-bottom: 2rem;
}
.\!wd-pl-57px {
  padding-left: 57px !important;
}
.wd-pb-4 {
  padding-bottom: 1rem;
}
.wd-pl-4 {
  padding-left: 1rem;
}
.wd-pl-2 {
  padding-left: 0.5rem;
}
.wd-pr-4 {
  padding-right: 1rem;
}
.wd-pb-10 {
  padding-bottom: 2.5rem;
}
.wd-pt-2 {
  padding-top: 0.5rem;
}
.wd-absolute {
  position: absolute;
}
.wd-relative {
  position: relative;
}
.wd-sticky {
  position: -webkit-sticky;
  position: sticky;
}
.wd-top-0 {
  top: 0px;
}
.wd-left-0 {
  left: 0px;
}
.wd-top-1 {
  top: 0.25rem;
}
.wd-top-\[5px\] {
  top: 5px;
}
.wd-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0/0.1),0 1px 2px -1px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px -1px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.wd-text-left {
  text-align: left;
}
.wd-text-right {
  text-align: right;
}
.wd-text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(230, 92, 92, var(--tw-text-opacity));
}
.wd-text-\[\#0ca6f2\] {
  --tw-text-opacity: 1;
  color: rgba(12, 166, 242, var(--tw-text-opacity));
}
.wd-text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(249, 212, 139, var(--tw-text-opacity));
}
.wd-text-\$Success-5 {
  color: var(--Success-5);
}
.wd-truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wd-underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.hover\:wd-no-underline:hover {
  text-decoration: none;
}
.wd-align-middle {
  vertical-align: middle;
}
.wd-invisible {
  visibility: hidden;
}
.wd-whitespace-pre-line {
  white-space: pre-line;
}
.wd-break-all {
  word-break: break-all;
}
.wd-w-full {
  width: 100%;
}
.wd-w-3\/12 {
  width: 25%;
}
.wd-w-\[max-content\] {
  width: max-content;
}
.wd-w-\[280px\] {
  width: 280px;
}
.wd-z-1 {
  z-index: 1;
}
.wd-z-10 {
  z-index: 10;
}
.wd-gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.wd-gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}
.wd-gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.wd-gap-10 {
  grid-gap: 2.5rem;
  gap: 2.5rem;
}
.wd-gap-8 {
  grid-gap: 2rem;
  gap: 2rem;
}
.wd-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.wd-grid-cols-24 {
  grid-template-columns: repeat(24, minmax(0, 1fr));
}
.wd-col-span-4 {
  -ms-grid-column-span: span 4 / span 4;
  grid-column: span 4 / span 4;
}
.wd-col-span-3 {
  -ms-grid-column-span: span 3 / span 3;
  grid-column: span 3 / span 3;
}
.wd-col-span-13 {
  -ms-grid-column-span: span 13 / span 13;
  grid-column: span 13 / span 13;
}
.wd-col-span-24 {
  -ms-grid-column-span: span 24 / span 24;
  grid-column: span 24 / span 24;
}
.wd-transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-translate-z: 0;
  --tw-rotate: 0;
  --tw-rotate-x: 0;
  --tw-rotate-y: 0;
  --tw-rotate-z: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scale-z: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) translateZ(var(--tw-translate-z)) rotate(var(--tw-rotate)) rotateX(var(--tw-rotate-x)) rotateY(var(--tw-rotate-y)) rotateZ(var(--tw-rotate-z)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) scaleZ(var(--tw-scale-z));
}
.wd--rotate-180 {
  --tw-rotate: -180deg;
}
.wd-rotate-90 {
  --tw-rotate: 90deg;
}
.wd-transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, filter, backdrop-filter;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform, filter, backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}
.wd-transition-transform {
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
}