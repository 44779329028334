.ui.form,
.tui-form,
.input {
  input:hover {
    border-color: @L3;
  }
  // @FIXME remove it after used new form
  .mantine-TextInput-invalid:hover {
    border-color: @S3-1;
  }
}
