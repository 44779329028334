/*******************************
         Site Overrides
*******************************/

/* Link */
.ui.breadcrumb a:hover {
  text-decoration: underline;
}

/* Section */
.ui.breadcrumb .section:not(a) {
  color: @lightTextColor;
}
