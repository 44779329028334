/*******************************
         Site Overrides
*******************************/

/*--------------
     Icon
---------------*/

.ui.button.icon {
  background: transparent;
}

.ui.button.disabled.primary {
  box-shadow: none !important;
}

.ui.disabled.button,
.ui.button:disabled,
.ui.disabled.button:hover,
.ui.disabled.active.button {
  background-color: #fafafa !important;
  color: @F4 !important;

  &.primary {
    background-color: #ccc !important;
    color: @white !important;
  }
}

.ui.disabled.primary.button.loading {
  background-color: rgba(@M3, 0.4) !important;

  &.negative {
    background-color: rgba(@red, 0.4) !important;
  }
}
